import { env } from "md-base-tools/env";
import { mapState } from "vuex";
import { Dialog, showSuccessToast } from 'vant';
import Error from "@@/common/Error.vue";
import { getLogisticsList } from "@/api";
import ClipboardJS from "clipboard";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      showError: false,
      showMessage: "",
      postage_order_no: this.$route.query.postage_order_no,
      logistics_list: [],
      copyboard: null
    };
  },
  beforeMount() {},
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    // if (this.env.isModianIos() || this.env.isModianAndroid()) {
    //     // eslint-disable-next-line no-undef
    //     userHeader.backnavOnShow(true);
    // }
    window.userHeader.titleText("查看物流");
    await this.getInfo();
    this.logistics_list.forEach((ele, index) => {
      let target = this.$refs.copyboard[index];
      let copyboard = new ClipboardJS(target, {
        text(target) {
          return target.getAttribute("copyData");
        }
      });
      copyboard.on("success", () => {
        // eslint-disable-next-line
        showSuccessToast("复制成功");
      });
    });
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = "1.6rem";
  },
  methods: {
    // 获取list
    async getInfo() {
      let option = {
        postage_order_no: this.postage_order_no
      };
      const {
        status,
        data,
        message
      } = await getLogisticsList(option);
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "暂无数据";
        return;
      } else {
        this.showError = false;
        this.logistics_list.push(...data);
      }
    },
    copy(i) {
      document.getElementsByTagName('input')[i].select();
      try {
        if (document.execCommand('copy', false, null)) {
          showSuccessToast('复制成功');
        } else {
          // IOS不支持时候重新初始化Clipboard
          var copyTarget = this.$refs.copyboard[i];
          var copyboard = new ClipboardJS(copyTarget, {
            text(copyTarget) {
              return copyTarget.getAttribute('copyData');
            }
          });
          copyboard.on('success', () => {
            showSuccessToast('复制成功');
          });
        }
      } catch (err) {
        mdToast(err);
      }
    },
    goDetail(express_name, express_no) {
      this.$router.push({
        path: "/luckycards/logistics_detail",
        query: {
          postage_order_no: this.postage_order_no,
          express_name: express_name,
          express_no: express_no
        }
      });
    },
    alertMessage() {
      Dialog({
        message: '暂无详细物流信息',
        confirmButtonColor: "#00C4A1"
      }).then(() => {});
    }
  }
};